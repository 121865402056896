import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '../styles/theme/theme';
import '../styles/all.sass';
import GlobalStyle from '../styles/GlobalStyle';

import Footer from './Footer';

const TemplateWrapper = ({ children }) => {
	return (
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyle />
				<main role="main">{children}</main>
				<Footer />
			</>
		</ThemeProvider>
	);
};

export default TemplateWrapper;
