const colors = require('./colors');
const fonts = require('./fonts');

module.exports = {
	colors: {
		primary: colors.peachBurst,
		secondary: colors.oceanLiner,
		tertiary: colors.floweringCactus,
		quaternary: colors.melonBaby,
	},
	fonts: {
		primary: fonts.objektiv3,
		secondary: fonts.objektiv1,
		weights: {
			regular: '400',
			bold: '700',
		},
	},
};
