import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  html {
    font-size: 10px;
  }

  body {
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 1.6rem;
    color: #ffffff;
    overflow: hidden;
  }

  @keyframes scrollArrow {
    0% {
        transform: scale(1) rotate(0);
    }
    25% {
        transform: scale(1) rotate(0);    
    }
    50% {
        transform: scale(1) rotate(0);
    }
    60% {
        transform: scale(1) rotate(0);
    }
    70% {
        transform: scale(1.5) rotate(0);    
    }
    80% {
        transform: scale(1) rotate(0);
    }
    85% {
        transform: scale(1) rotate(15deg);
    }
    90% {
        transform: scale(1) rotate(-15deg);
    }
    95% {

    }
    100% {
        transform: scale(1) rotate(0);
    }
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
  }
`;

export default GlobalStyle;
