import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const StyledFooter = styled.footer`
	.footer-top {
		height: calc(8vw + 15rem);

		@media (min-width: 1920px) {
			height: 32rem;
		}
	}

	.footer-bottom {
		font-weight: ${(props) => props.theme.fonts.weights.regular};
		color: #ffffff;
		background-color: ${(props) => props.theme.colors.secondary};
		padding: 0.75rem 2rem;
		small {
			font-size: 1.4rem;

			@media (max-width: 460px) {
				font-size: 2.9vw;
			}
		}
	}
`;

const Footer = () => {
	const data = useStaticQuery(graphql`
		query Footer {
			file(name: { eq: "LBMC_Footer" }) {
				childImageSharp {
					fluid(maxWidth: 1920, quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<StyledFooter className="has-background-black">
			<div
				className="footer-top"
				style={{
					backgroundImage: `url(${
						!!data.file.childImageSharp
							? data.file.childImageSharp.fluid.src
							: data.file
					})`,
					backgroundPosition: `center`,
					backgroundSize: `cover`,
					backgroundRepeat: `no-repeat`,
				}}
			/>
			<div className="footer-bottom has-text-centered">
				<small>
					&copy; {new Date().getFullYear()}, Little Buddha Mocktails. All Rights
					Reserved.
				</small>
			</div>
		</StyledFooter>
	);
};

export default Footer;
